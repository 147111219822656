import React from "react"

import { TextBlock } from "../../../UI/Common"

import PaddingWrapper from "./PaddingWrapper"

const TextBlockContent = ({ text, config }) => (
  <PaddingWrapper padding={config?.padding}>
    <TextBlock text={text} />
  </PaddingWrapper>
)

export default TextBlockContent
